define("ember-leaflet/components/interactive-layer", ["exports", "ember-leaflet/components/base-layer", "ember-leaflet/components/popup-layer", "ember-leaflet/components/tooltip-layer"], function (_exports, _baseLayer, _popupLayer, _tooltipLayer) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  /**
   * Some Layers can be made interactive - when the user interacts with such a layer,
   * mouse events like click and mouseover can be handled. Use the event handling methods
   * to handle these events.
   *
   * @class InteractiveLayer
   * @extends BaseLayer
   */
  class InteractiveLayer extends _baseLayer.default {
    constructor(...args) {
      super(...args);
      _defineProperty(this, "leafletOptions", [...this.leafletOptions,
      /**
       * If `false`, the layer will not emit mouse events and will act as a part of the underlying map.
       * Defaults to `true`.
       *
       * @argument interactive
       * @type {Boolean}
       */
      'interactive',
      /**
       * When true, a mouse event on this layer will trigger the same event on the map.
       * Defaults to `true`.
       *
       * @argument bubblingMouseEvents
       * @type {Boolean}
       */
      'bubblingMouseEvents']);
      _defineProperty(this, "leafletEvents", [...this.leafletEvents,
      /**
       * Fired when the user clicks (or taps) the layer.
       *
       * @argument onClick
       * @type {Function}
       */
      'click',
      /**
       * Fired when the user double-clicks (or double-taps) the layer.
       *
       * @argument onDblclick
       * @type {Function}
       */
      'dblclick',
      /**
       * Fired when the user pushes the mouse button on the layer.
       *
       * @argument onMousedown
       * @type {Function}
       */
      'mousedown',
      /**
       * Fired when the user releases the mouse button pushed on the layer.
       *
       * @argument onMouseup
       * @type {Function}
       */
      'mouseup',
      /**
       * Fired when the mouse enters the layer.
       *
       * @argument onMouseover
       * @type {Function}
       */
      'mouseover',
      /**
       * Fired when the mouse leaves the layer.
       *
       * @argument onMouseout
       * @type {Function}
       */
      'mouseout',
      /**
       * Fired when the user right-clicks on the layer, prevents default browser context menu
       * from showing if there are listeners on this event. Also fired on mobile when the user
       * holds a single touch for a second (also called long press).
       *
       * @argument onContextmenu
       * @type {Function}
       */
      'contextmenu']);
      _defineProperty(this, "componentsToYield", [...this.componentsToYield, {
        name: 'popup-layer',
        as: 'popup',
        component: _popupLayer.default
      }, {
        name: 'tooltip-layer',
        as: 'tooltip',
        component: _tooltipLayer.default
      }]);
    }
  }
  _exports.default = InteractiveLayer;
});